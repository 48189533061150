
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexvWIzHydyUEV3WuDN1DL_fUvDkcum1U405J3W1xCWMq8Meta } from "/mono/app/pages/creative/index.vue?macro=true";
import { default as indexRPxF3owxH8lXzxIJZlGxBNnzXxUIVAAikQoZah3YmhIMeta } from "/mono/app/pages/index.vue?macro=true";
import { default as indexb4PPDraZAlnG5tAi0D1N9qrGa8bmUQLS2AbiB4kO9W8Meta } from "/mono/app/pages/resume/index.vue?macro=true";
import { default as indexaAQH0UfDMizTpskVvD7aQZ_aK7y7Jb8SvFl_0ZDh8jwMeta } from "/mono/app/pages/Résumé/index.vue?macro=true";
import { default as indexQpNOuhoT_45L9SKh_rVJcxYSsah3jIZpwZDkQRE3jINaEMeta } from "/mono/app/pages/roxxon-corp/index.vue?macro=true";
import { default as indexXdJrugEer3yAat_45dcEYxmviXEFnQnZiNr4_45lnWTGErgMeta } from "/mono/app/pages/summary/index.vue?macro=true";
import { default as component_45stubxZA_45xFtFDexAwhbcZdeLro8jbnAylK_45DYJzHEQsl95kMeta } from "/mono/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubxZA_45xFtFDexAwhbcZdeLro8jbnAylK_45DYJzHEQsl95k } from "/mono/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "creative",
    path: "/creative",
    component: () => import("/mono/app/pages/creative/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/mono/app/pages/index.vue")
  },
  {
    name: "resume",
    path: "/resume",
    component: () => import("/mono/app/pages/resume/index.vue")
  },
  {
    name: "Résumé",
    path: "/R%C3%A9sum%C3%A9",
    component: () => import("/mono/app/pages/Résumé/index.vue")
  },
  {
    name: "roxxon-corp",
    path: "/roxxon-corp",
    component: () => import("/mono/app/pages/roxxon-corp/index.vue")
  },
  {
    name: "summary",
    path: "/summary",
    component: () => import("/mono/app/pages/summary/index.vue")
  },
  {
    name: component_45stubxZA_45xFtFDexAwhbcZdeLro8jbnAylK_45DYJzHEQsl95kMeta?.name,
    path: "/r%C3%A9sum%C3%A9",
    component: component_45stubxZA_45xFtFDexAwhbcZdeLro8jbnAylK_45DYJzHEQsl95k
  },
  {
    name: component_45stubxZA_45xFtFDexAwhbcZdeLro8jbnAylK_45DYJzHEQsl95kMeta?.name,
    path: "/backend",
    component: component_45stubxZA_45xFtFDexAwhbcZdeLro8jbnAylK_45DYJzHEQsl95k
  },
  {
    name: component_45stubxZA_45xFtFDexAwhbcZdeLro8jbnAylK_45DYJzHEQsl95kMeta?.name,
    path: "/roxxon",
    component: component_45stubxZA_45xFtFDexAwhbcZdeLro8jbnAylK_45DYJzHEQsl95k
  }
]