<template>
  <div>
    <NuxtRouteAnnouncer />
    <CarbonLoading />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import '~/assets/css/main.css'
import '~/assets/css/carbon-gray-10.css'

const title = 'David Nixon'
const description = 'Random thoughts; C++, Java, Vue, Nuxt, DevOps, Travis, IBM Cloud'
const imageUrl = '/social-image.jpg'
useSeoMeta({
  title: title,
  ogTitle: title,
  description: description,
  ogDescription: description,
  ogImage: imageUrl,
  twitterImage: imageUrl,
  twitterCard: 'summary_large_image',
  twitterDescription: description,
  twitterTitle: title,
})
useHead({
  script: { 'src': '//gc.zgo.at/count.js', 'data-goatcounter': process.env.GOATCOUNTER_ENDPOINT },
})
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}
</style>
